import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { checkAdmin } from "../utils/auth";
import { logOut } from "../Log_Reg/UserFunctions";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin: false,
            isHelpOpen: false,
        };
    }

    componentDidMount = () => {
        this.isAdmin();
        document.addEventListener("click", this.closeMenuIfClickOutside);
    };

    componentWillUnmount = () => {
        document.removeEventListener(
            "click",
            this.closeMenuIfClickOutside,
            false
        );
    };

    /**
     * If the menu is open and the user is hovering over the menu, then if the user clicks outside of
     * the menu, close the menu.
     * @param e - the event object
     */
    closeMenuIfClickOutside = (e) => {
        if (this.props.isMenuOpen && this.props.isHoverMenu) {
            if (e.target.getAttribute("data-click-info") === null) {
                if (
                    e.target.parentNode.getAttribute("data-click-info") === null
                ) {
                    if (
                        e.target.parentNode.parentNode.getAttribute(
                            "data-click-info"
                        ) === null
                    ) {
                        this.props.setMenuOpen(false);
                        this.props.setHoverMenu(false);
                    }
                }
            }
        }
    };
    isAdmin = async () => {
        if (this.state.admin === false) {
            try {
                let response = await checkAdmin();
                this.setState({ admin: response.data });
            } catch (error) {}
        }
    };
    toggleMenu = () => {
        this.props.setMenuOpen(!this.props.isMenuOpen);
    };

    /**  A function that toggles the menu of help. */
    toggleHelp = () => {
        this.setState({ isHelpOpen: !this.state.isHelpOpen });
    };

    /* A function that toggles the menu of profile. */
    toggleProfile = () => {
        if (this.props.isProfileOpen === true) {
            document.getElementById("ModalProfile").classList.remove("hidden");
            document.getElementById("blackScreen").classList.add("blackScreen");
        } else {
            document.getElementById("ModalProfile").classList.add("hidden");
            document
                .getElementById("blackScreen")
                .classList.remove("blackScreen");
            document.getElementById("blackScreen").classList.add("hidden");
        }
    };

    /**
     * If the logOut function returns a status of 200, then  log out.
     */
    logOutAndRedirect = () => {
        this.props.logOut();
    };

    render() {
        return (
            <>
                <ul className="list-none w-100 p-4 relative">
                    <li
                        className={`flex justify-between content-center font-light text-sm cursor-pointer my-2 text-gray-600 ${
                            this.props.isHoverMenu ? "text-black " : ""
                        } h-10`}
                    >
                        <NavLink
                            to="/"
                            className={`${
                                this.props.isHoverMenu ? "block" : "hidden"
                            }  transition-all delay-300 text-sm`}
                        >
                            {this.props.t("common.menu.screens")}
                        </NavLink>
                        <FontAwesomeIcon
                            icon={["fal", "sign"]}
                            className="ml-2"
                        />
                    </li>
                    <li
                        className={`flex justify-between content-center font-light text-sm cursor-pointer my-2 text-gray-600 ${
                            this.props.isHoverMenu ? "text-black " : ""
                        } h-10`}
                    >
                        <NavLink
                            to="/content"
                            className={`${
                                this.props.isHoverMenu ? "block" : "hidden"
                            }  transition-all delay-300 text-sm`}
                        >
                            {this.props.t("common.menu.content")}
                        </NavLink>
                        <FontAwesomeIcon
                            icon={["fal", "image-polaroid"]}
                            className="ml-2"
                        />
                    </li>
                    <li
                        className={`flex justify-between content-center font-light text-sm cursor-pointer my-2 text-gray-600 ${
                            this.props.isHoverMenu ? "text-black " : ""
                        } h-10`}
                    >
                        <NavLink
                            to="/reports"
                            className={`${
                                this.props.isHoverMenu ? "block" : "hidden"
                            }  transition-all delay-300 text-sm`}
                        >
                            {this.props.t("common.menu.reports")}
                        </NavLink>
                        <FontAwesomeIcon
                            icon={["fal", "chart-bar"]}
                            className="ml-2"
                        />
                    </li>
                    <li
                        className={`flex justify-between content-center font-light text-sm cursor-pointer my-2 text-gray-600 ${
                            this.props.isHoverMenu ? "text-black " : ""
                        } h-10`}
                    >
                        <NavLink
                            to="/scheduler"
                            className={`${
                                this.props.isHoverMenu ? "block" : "hidden"
                            }  transition-all delay-300 text-sm`}
                        >
                            {this.props.t("common.menu.calendar")}
                        </NavLink>
                        <FontAwesomeIcon
                            icon={["fal", "calendar-alt"]}
                            className="ml-2"
                        />
                    </li>
                </ul>
                <ul className="list-none w-full p-4 absolute bottom-0">
                    <li
                        className={`flex justify-between content-center font-light text-sm cursor-pointer my-2 text-gray-600 ${
                            this.props.isHoverMenu ? "text-black " : ""
                        } h-10`}
                    >
                        <NavLink
                            to="/management"
                            className={`${
                                this.props.isHoverMenu ? "block" : "hidden"
                            }  transition-all delay-300 text-sm`}
                        >
                            {this.props.t("common.menu.management")}
                        </NavLink>
                        <FontAwesomeIcon
                            icon={["fal", "cog"]}
                            className="ml-2"
                        />
                    </li>
                    <li
                        data-click-info="menu-options"
                        onClick={() => {
                            this.toggleMenu();
                        }}
                        className={`flex justify-between content-center font-light text-sm cursor-pointer my-2 text-gray-600 ${
                            this.props.isHoverMenu ? "text-black " : ""
                        } h-10`}
                    >
                        <p
                            className={`${
                                this.props.isHoverMenu ? "block" : "hidden"
                            }  transition-all delay-300 text-sm`}
                        >
                            {this.props.t("common.menu.user")}
                        </p>
                        <div className=" z-50 flex content-center justify-center h-8 rounded-full backdrop bg-white bg-opacity-50">
                            <FontAwesomeIcon
                                icon={["fal", "user"]}
                                size="1x"
                                className=" ml-2"
                            />
                            <div onClick={this.toggleMenu} className="relative">
                                <div
                                    className={`${
                                        this.props.isMenuOpen
                                            ? " bg-white left-4 listDad h-24 bg-opacity-90 shadow-lg"
                                            : "hidden top-0 shadow-none"
                                    } absolute transition transform duration-300 ease-in-out rounded text-xs cursor-default`}
                                >
                                    <ul className="list space-y-2">
                                        <li
                                            onClick={() => {
                                                this.props.handleModalsOpen(
                                                    "profile",
                                                    this.toggleProfile
                                                );
                                            }}
                                            className="cursor-pointer flex items-center text-gray-400 hover:text-black truncate"
                                        >
                                            <FontAwesomeIcon
                                                icon={["far", "user"]}
                                                fixedWidth
                                                size="1x"
                                                className=" mr-1 text-gray-400 hover:text-red-800"
                                            />{" "}
                                            <span>
                                                {this.props.t(
                                                    "common.menu.profile"
                                                )}
                                            </span>
                                        </li>
                                        <li
                                            onClick={() => {
                                                this.props.handleModalsOpen(
                                                    "help",
                                                    this.toggleProfile
                                                );
                                            }}
                                            className="cursor-pointer flex items-center content-center text-gray-400 hover:text-black truncate"
                                        >
                                            <FontAwesomeIcon
                                                icon={["far", "life-ring"]}
                                                fixedWidth
                                                size="1x"
                                                className="mr-1 text-gray-400 hover:text-red-800"
                                            />{" "}
                                            {this.props.t("common.menu.help")}
                                        </li>
                                        <li
                                            onClick={this.logOutAndRedirect}
                                            className="cursor-pointer flex items-center content-center text-red-700 hover:text-black truncate"
                                        >
                                            <FontAwesomeIcon
                                                icon={["fad", "sign-out"]}
                                                fixedWidth
                                                size="1x"
                                                className="mr-1 text-red-400 hover:text-red-800 "
                                            />
                                            {this.props.t(
                                                "common.menu.signout"
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </>
        );
    }
}
export default withTranslation()(Menu);
