import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, version } from 'react'
import { withTranslation } from 'react-i18next'
import Title from '../common/Title';
import { getPlayerVersionLatest } from '../../Management.Functions';
import { capitalize } from '../../../utils/commonFunctions';
import cloneDeep from 'lodash.clonedeep';

class PlayersLayout extends Component {
    constructor() {
        super();
        this.state = {
            os: [
                {
                    "name": "Windows",
                    "available": true,
                    "version": "...",
                    "icon": ["fab", "windows"],
                    "color": "text-blue-400",
                    "checkedVersion": false,
                    "actualOs": false
                }
                , {
                    "name": "Linux",
                    "available": true,
                    "version": "...",
                    "icon": ["fab", "linux"],
                    "color": "text-yellow-300",
                    "checkedVersion": false,
                    "actualOs": false
                }
                , {
                    "name": "MacOS",
                    "available": false,
                    "version": "...",
                    "icon": ["fab", "apple"],
                    "color": "text-gray-700",
                    "checkedVersion": false,
                    "actualOs": false
                }
                , {
                    "name": "Android",
                    "available": false,
                    "version": "...",
                    "icon": ["fab", "android"],
                    "color": "text-green-500",
                    "checkedVersion": false,
                    "actualOs": false
                }
            ],
            actualOsHasChanged: false,
            loading: true
        }
    }

    componentDidMount = async () => {
        let systems = cloneDeep(this.state.os)
        let actualOs = this.getOperatingSystem(systems);
        actualOs = await this.getLatestVersion(actualOs);
        actualOs.actualOs = true;
        let index = systems.findIndex(obj => obj.name === actualOs.name);
        systems[index] = actualOs;
        this.setState({ os: systems, loading: false })
    }
    /**
     * return the object that has a property "checkedVersion" in true.
     * @param {array} Systems 
     * @returns {object} operating system
     */
    getCheckedVersion = (Systems) => {
        let osByCheckedVersion = Systems.find(os => os.checkedVersion);
        if (osByCheckedVersion) {
            return osByCheckedVersion;
        }
    }

    /**
     * Through the passed parameter and environment variables, 
     * a dynamic url is generated and returned, 
     * which varies according to the operating system and the white label.
     * @param {object} os represents the current operating system with properties such as the name, the version, if it is updated, etc.
     * @returns {string} url
     */
    generateLinkDowloadPlayer = (os) => {
 
        let url = `https://bucket-teste-bebot.s3.us-east-1.amazonaws.com/BebotPlayerSetup-2.4.0.exe`;
        
        return url;
    }
    //
    /**
     * Replace words of a string.
     * @param {*} string the character string.
     * @param {*} wordToReplace word that you wanna replace.
     * @param {*} replacement the new word.
     * @returns {string} the new String.
     */
    replaceWords = (string, wordToReplace, replacement) => {
        return string.replace(new RegExp(wordToReplace, 'g'), replacement);
    }


    /**
     * It checks if the version of the player on the current operating system is updated, 
     * if it is not the case, it downloads and saves it.
     * then return the object
     * @param {object} os represents the current operating system with properties such as the name, the version, if it is updated, etc.
     * @returns {object} the object representing the player with the updated version.
     */
    getLatestVersion = async (os) => {
         
                    os.checkedVersion = true;
                    os.version = "1.2.3";
                    return os;
                
        

    }
    /**
     * Identifies and returns the object representing the current operating system.
     * @param {array} systems array of operating systems
     * @returns {obj} object that represent the current operating system  
     */
    getOperatingSystem = (systems) => {
        const platform = navigator.platform;
        if (/Mac/.test(platform)) {
            return systems.find(e => e.name === "MacOS");
        } else if (/Linux/.test(platform)) {
            return systems.find(e => e.name === "Linux");
        } else if (/Android/.test(platform)) {
            return systems.find(e => e.name === "Android");
        } else {
            return systems.find(e => e.name === "Windows");
        }
    }

    /**
     * Filters and returns all operating systems except the current one
     * @param {*} os - the actual os
     * @returns {array} Array of operating systems that are currently showing.
     */
    getOthersSystems = (os) => {
        let other = os.filter(s => !s.actualOs);
        return other;
    }

    /**
     * changes the checked Version property of the current os to the one that was selected and sets all other os to false.
     * If the version of the new player is outdated, it is updated.
     * @param {object} os the new os that is set as the current operating system
     */
    changeActualOs = (os) => {
        if (!os.checkedVersion) {
            this.updateVersion(os);
        }
        let systems = this.state.os;
        systems.forEach(s => {
            if (s.name !== os.name) {
                s.actualOs = false;
            } else {
                s.actualOs = true;
            }
        })
        let index = systems.findIndex(obj => obj.name === os.name);
        systems[index] = os;
        this.setState({ os: systems, actualOsHasChanged: true })
    }
    /**
     * if os.getCheckedVersion has in false update version of player
     * @param {object} os 
     */
    updateVersion = async (os) => {
        if (!os.checkedVersion) {
            try {
                let systems = this.state.os;
                let actualOs = await this.getLatestVersion(os);
                let index = systems.findIndex(obj => obj.name === actualOs.name);
                systems[index] = actualOs;
                this.setState({ os: systems })
            } catch (error) {
                this.props.showNotification({
                    type: 'warning',
                    text: this.props.t("sections.management.section.players.noPlayer"),
                    duration: 3000
                });
            }
        }
    }
    /**
    * Parse and return only player version.
    * @param {*} string text with the whiteLabel and the version of the player
    * @returns {string} only the version
    */
    extractVersion = (string) => {
        if (typeof (string) !== "string") {
            return null;
        }
        const regex = /\d+(\.\d+)+/;
        const match = string.match(regex);
        if (match) {
            return match[0];
        } else {
            return null;
        }
    }
    /**
     * return an object from array through a prop that has to be boolean
     * @param {boolean} atribute example: "checkedVersion:boolean" or "actualOs:boolean"
     * @returns {object} os represents the current operating system with properties such as the name, the version, if it is updated, etc.
     */
    getOsFromProperties = (atribute) => {
        let systems = cloneDeep(this.state.os);
        let os = systems.find(obj => obj[atribute] === true)
        if (os) {
            return os;
        }

    }

    render() {
        
        return (
            <div className=''>
                <Title>
                    <FontAwesomeIcon icon={["fal", "play-circle"]} fixedWidth className="text-blue-500" /> {this.props.t("sections.management.section.players.title")}
                    <p className="font-light">{this.props.t("sections.management.section.players.playersTxt")}</p>
                </Title>
                <div className='flex flex-wrap  w-100 gap-3  md:justify-center lg:justify-start'>
                    <div className="flex flex-wrap flex-col w-full md:w-3/4 lg:w-1/4 gap-3">
                        <div className='bg-gray-100 shadow rounded h-60 '>

                            {!this.state.loading ?
                                <>
                                    <div className='h-full w-full  flex flex-col justify-center items-center'>
                                        <div className='h-2/3 flex flex-col justify-center items-center'>
                                            <div className='flex flex-col items-center transition duration-300 transform  '>
                                                <FontAwesomeIcon icon={[this.getOsFromProperties("actualOs")?.icon[0], this.getOsFromProperties("actualOs")?.icon[1]]} className={`text-8xl text-gray-400 `} />
                                                <span className='text-sm text-gray-400'>{this.getOsFromProperties("actualOs")?.name} <span className=''>
                                                    {
                                                        this.extractVersion(this.getOsFromProperties("actualOs").version) ?
                                                            this.extractVersion(this.getOsFromProperties("actualOs").version) :
                                                            "x.x.x"
                                                    }
                                                </span></span>
                                            </div>
                                        </div>

                                        {this.getOsFromProperties("actualOs")?.available === true ?
                                            <div className='h-1/5 flex flex-col justify-center items-center'>
                                                {this.getOsFromProperties("actualOs")?.version === "..." ?
                                                    <FontAwesomeIcon icon={["fal", "circle-notch"]} size="4x" fixedWidth className={`p-2 mx-1 animate-spin text-blue-300`} />
                                                    :
                                                    <a href={this.generateLinkDowloadPlayer(this.getOsFromProperties("actualOs"))} rel="noreferrer">
                                                        <button disabled={this.getOsFromProperties("actualOs").version === "x.x.x"} className={`p-2 rounded-sm text-sm w-40 text-white disabled:bg-gray-400 disabled:cursor-not-allowed bg-blue-400 `}>{this.props.t("sections.management.section.players.download")}</button>
                                                    </a>
                                                }
                                            </div>
                                            :
                                            <div className='h-1/5 flex flex-col justify-center items-center'>
                                                <button disabled={this.getOsFromProperties("actualOs").version === "x.x.x"} className='p-2 rounded-sm  text-sm bg-gray-400 disabled:bg-gray-400 disabled:cursor-not-allowed w-40 text-white'>{this.props.t("sections.management.section.players.noPlayer")}</button>
                                            </div>
                                        }
                                    </div>

                                </>
                                :
                                <>
                                    <div role="status" class="space-y-8 animate-pulse  w-full h-full flex justify-center flex-col items-center">
                                        <div class="w-20 h-20 bg-gray-200 rounded-full"></div>

                                    </div>
                                </>
                            }
                        </div>
                        <div>
                            {
                                !this.state.loading ?
                                    <div className='flex gap-3  justify-between'>
                                        {
                                            this.getOthersSystems(this.state.os).map(e => {
                                                let icon1 = ""
                                                let icon2 = ""
                                                icon1 = e.icon[0]
                                                icon2 = e.icon[1]
                                                return <div className='w-1/3 bg-gray-100  shadow rounded h-20 flex flex-col justify-center items-center cursor-pointer ' onClick={() => this.changeActualOs(e)}>
                                                    <div className='transition duration-200 transform hover:scale-125 '>
                                                        <FontAwesomeIcon icon={[icon1, icon2]} className={`text-5xl text-gray-400 `} />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div> :
                                    // skeleton 
                                    <div className='flex gap-3 animate-pulse justify-between'>
                                        <div className='w-1/3 bg-gray-100  shadow rounded h-20 flex flex-col justify-center items-center cursor-pointer ' >
                                            <div className='transition duration-200 transform hover:scale-125 '>
                                                <div class="w-10 h-10 bg-gray-200 rounded-full"></div>
                                            </div>
                                        </div>
                                        <div className='w-1/3 bg-gray-100  shadow rounded h-20 flex flex-col justify-center items-center cursor-pointer ' >
                                            <div className='transition duration-200 transform hover:scale-125 '>
                                                <div class="w-10 h-10 bg-gray-200 rounded-full"></div>

                                            </div>
                                        </div>
                                        <div className='w-1/3 bg-gray-100  shadow rounded h-20 flex flex-col justify-center items-center cursor-pointer ' >
                                            <div className='transition duration-200 transform hover:scale-125 '>
                                                <div class="w-10 h-10 bg-gray-200 rounded-full"></div>
                                            </div>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>

                    {/* requerimentsPlayer */}
                    <div className='flex flex-col justify-between w-full min-w-max md:w-3/4 lg:w-1/4 gap-3'>
                        <div className=' bg-gray-100 shadow rounded p-3 pb-1 h-1/2 flex flex-col justify-between'>
                            <div>
                                <strong className="font-bold  text-gray-500">{this.props.t("sections.management.section.players.minimumRequirements.title")}</strong>
                                <br />
                            </div>
                            <div className='text-gray-500 flex flex-col gap-1'>
                                <div>
                                    <span className='mx-1'>-</span>
                                    <strong className="font-bold">{this.props.t("sections.management.section.players.requerimentsPlayer.key.operatingSystem")}:</strong>
                                    {this.props.t("sections.management.section.players.minimumRequirements.operatingSystemExample")}
                                </div>
                                <div>
                                    <span className='mx-1'>-</span>
                                    <strong className="font-bold">{this.props.t("sections.management.section.players.requerimentsPlayer.key.hardDiskSpace")}:</strong> {this.props.t("sections.management.section.players.minimumRequirements.hardDiskSpaceExample")}
                                </div>
                                <div>
                                    <span className='mx-1'>-</span>
                                    <strong className="font-bold">{this.props.t("sections.management.section.players.requerimentsPlayer.key.processor")}:{" "}</strong>{this.props.t("sections.management.section.players.minimumRequirements.processorExample")}
                                </div>
                                <div>
                                    <span className='mx-1'>-</span>
                                    <strong className="font-bold">{this.props.t("sections.management.section.players.requerimentsPlayer.key.ram")}:</strong> {this.props.t("sections.management.section.players.minimumRequirements.ramExample")}
                                </div>
                            </div>
                        </div>
                        <div className=' bg-gray-100 shadow rounded p-3 pb-1 h-1/2 flex flex-col justify-between'>
                            <div>
                                <strong className="font-bold  text-gray-500">{this.props.t("sections.management.section.players.optimalRequirements.title")}</strong>
                                <br />
                            </div>
                            <div className='text-gray-500 flex flex-col gap-1'>
                                <div>
                                    <span className='mx-1'>-</span>
                                    <strong className="font-bold"><strong className="font-bold">{this.props.t("sections.management.section.players.requerimentsPlayer.key.operatingSystem")}:</strong>
                                    </strong> {this.props.t("sections.management.section.players.minimumRequirements.operatingSystemExample")}

                                </div>
                                <div>
                                    <span className='mx-1'>-</span>
                                    <strong className="font-bold">{this.props.t("sections.management.section.players.requerimentsPlayer.key.ssdisk")}:</strong> {this.props.t("sections.management.section.players.minimumRequirements.hardDiskSpaceExample")}
                                </div>
                                <div>
                                    <span className='mx-1'>-</span>
                                    <strong className="font-bold">{this.props.t("sections.management.section.players.requerimentsPlayer.key.processor")}:{" "}</strong>{this.props.t("sections.management.section.players.minimumRequirements.processorExample")}
                                </div>
                                <div>
                                    <span className='mx-1'>-</span>
                                    <strong className="font-bold">{this.props.t("sections.management.section.players.requerimentsPlayer.key.ram")}:</strong> {this.props.t("sections.management.section.players.optimalRequirements.ramExample")}
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div >
        )
    }
}
export default withTranslation()(PlayersLayout);
